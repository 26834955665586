import { AbstractControl, FormArray, ValidationErrors, ValidatorFn } from '@angular/forms';

import { ValidationErrorCode } from '../models/validation-error-code';

export namespace AppValidators {

	/**
	 * Checks whether the current control matches another.
	 * @param controlName Control name to check matching with.
	 * @param controlTitle Control title to display for a user.
	 */
	export function matchControl(
		controlName: string,
		controlTitle = controlName,
	): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (
				control.parent &&
        control.parent.get(controlName)?.value !== control.value
			) {
				return {
					[ValidationErrorCode.Match]: {
						controlName,
						controlTitle,
					},
				};
			}
			return null;
		};
	}

	/**
	 * Checks whether the control value is unique compared to other controls in FormArray.
	 * @param comparator Comparator function to check if 2 control values are equal.
	 */
	export function unique<T>(comparator?: (item1: T, item2: T) => boolean): ValidatorFn {

		return (control: AbstractControl<T>): ValidationErrors | null => {
			if (!control.dirty) {
				return null;
			}
			const parentFormArray = getParentFormArray(control);
			if (parentFormArray == null) {
				return null;
			}

			const isUnique = parentFormArray.controls.filter(
				childControl => (comparator ? comparator(childControl.value, control.value) : childControl.value === control.value),
			).length === 1;

			if (isUnique) {
				return null;
			}
			return { [ValidationErrorCode.Unique]: true };
		};
	}

	/**
	 * Create validation error from a message.
	 * @param message Message to create an error from.
	 */
	export function buildAppError(message: string): ValidationErrors {
		return {
			[ValidationErrorCode.AppError]: {
				message,
			},
		};
	}

	/**
	 * Get the nearest parent FormArray of the control. Returns null if does not exist.
	 * @param control Control.
	 */
	function getParentFormArray(control: AbstractControl): FormArray | null {
		if (control.parent && !(control.parent instanceof FormArray)) {
			return getParentFormArray(control.parent);
		}
		return control.parent;
	}
}
