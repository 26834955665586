import { z } from 'zod';

const passwordChangeDtoSchema = z.object({
	password: z.string(),
	password_confirmation: z.string(),
	token: z.string(),
	email: z.string(),
});

/** Forgot password dto schema. */
export const forgotPasswordDtoSchema = z.object({
	token: z.string(),
});

/** Change password DTO. */
export type PasswordChangeDto = Readonly<z.infer<typeof passwordChangeDtoSchema>>;

/** Forgot password token DTO. */
export type ForgotPasswordDto = Readonly<z.infer<typeof forgotPasswordDtoSchema>>;
