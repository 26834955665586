import { Injectable } from '@angular/core';

import { Pagination } from '../models/pagination';

import { ListResponseDto } from '../dtos/list-response.dto';

import { assertNonNull } from '../utils/assert-non-null';

import { MapperFromDto } from './mappers';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RecordAny = Record<string, any>;

/** List response mapper. */
@Injectable({
	providedIn: 'root',
})
export class ListResponseMapper {
	/**
	 * Map pagination from dto.
	 * @param dto Dto page.
	 * @param mapper Mapper for the items.
	 */
	public fromDto<TDto, TDomain extends RecordAny>(
		dto: ListResponseDto<TDto>,
		mapper: MapperFromDto<TDto, TDomain> | MapperFromDto<TDto, TDomain>['fromDto'],
	): Pagination<TDomain> {
		assertNonNull(dto.meta);
		const { pagination } = dto.meta;
		return new Pagination({
			items: dto.data.map(typeof mapper === 'function' ? mapper : mapper.fromDto.bind(mapper)),
			totalCount: pagination.total,
			hasNext: pagination.links.next != null,
			hasPrev: pagination.links.prev != null,
		});
	}
}
