import { Injectable } from '@angular/core';

import { BaseFilterParams } from '../models/base-filter-params';
import { PaginationQueryParamDto } from '../dtos/pagination.dto';

import { MapperToDto } from './mappers';

/** Pagination mapper. */
@Injectable({
	providedIn: 'root',
})
export class PaginationParamsMapper implements MapperToDto<PaginationQueryParamDto, BaseFilterParams.Pagination> {
	/** @inheritdoc */
	public toDto(model: BaseFilterParams.Pagination): PaginationQueryParamDto {
		return {
			page: model.pageNumber + 1,
			per_page: model.pageSize,
		};
	}
}
