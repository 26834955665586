import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

import { formatDate } from '@mei/common/core/utils/date-utils';

import { CardComponent } from '../card/card.component';

/** Snackbar data. */
export type SnackBarData = Readonly<{

	/** Content. */
	content: string | null;

	/** Title. */
	title: string;

	/** Type. */
	type: 'error' | 'warning' | 'info' | 'success';

	/** Notify date. */
	notifyDate?: Date;
}>;

/** Snackbar component. */
@Component({
	selector: 'meic-snackbar',
	templateUrl: './snackbar.component.html',
	styleUrl: './snackbar.component.css',
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [MatIconModule, MatButtonModule, CardComponent],
})
export class AlertComponent {
	/** Snackbar data. */
	public readonly snackbarData = inject<SnackBarData>(MAT_SNACK_BAR_DATA);

	private readonly snackbarRef = inject(MatSnackBarRef);

	/** Dismiss alert. */
	protected onDismissAlert(): void {
		this.snackbarRef.dismiss();
	}

	/**
	 * To readable notify date.
	 * @param date Date value.
	 */
	protected toReadableNotifyDate(date: Date): string {
		return `${formatDate(date, 'DD')} at ${formatDate(date, 't')}`;
	}
}
