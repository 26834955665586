import { PaginationQueryParamDto } from './pagination.dto';

/** User sort field dto.. */
export enum UserSortFieldDto {
	Name = 'name',
	Email = 'email',
	Role = 'role',
	Company = 'company',
}

/** User filter params dto. */
export type UserFilterParamsDto = PaginationQueryParamDto & {

	/** Key to search user name. */
	readonly name: string;

	/** Sorting options. */
	readonly sort?: string;
};
