import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Login } from '../models/login';

import { AppErrorMapper } from '../mappers/app-error.mapper';
import { LoginDataMapper } from '../mappers/login-data.mapper';

import { AppUrlsConfig } from './app-urls.config';

/**
 * Performs CRUD operations for auth-related information.
 */
@Injectable({ providedIn: 'root' })
export class AuthApiService {

	private readonly httpClient = inject(HttpClient);

	private readonly apiUrlsConfig = inject(AppUrlsConfig);

	private readonly loginDataMapper = inject(LoginDataMapper);

	private readonly appErrorMapper = inject(AppErrorMapper);

	/**
	 * Login a user with email and password.
	 * @param loginData Login data.
	 */
	public login(loginData: Login): Observable<void> {
		return this.httpClient.post<unknown>(
			this.apiUrlsConfig.auth.login,
			this.loginDataMapper.toDto(loginData),
		)
			.pipe(
				map(() => undefined),
				this.appErrorMapper.catchHttpErrorToAppError(),
			);
	}

	/** Logout the user. */
	public logout(): Observable<void> {
		return this.httpClient.delete(
			this.apiUrlsConfig.auth.logout,
		).pipe(
			map(() => undefined),
		);
	}
}
