import { type z } from 'zod';

/**
 * Parse dto without throwing zod error and continue mapping process.
 * @param dto Data transfer object.
 * @param schema Zod schema.
 **/
export const secureParse = <T>(dto: unknown, schema: z.ZodType<T>): T => {
	const result = schema.safeParse(dto);
	if (result.success) {
		return result.data;
	}
	console.error(result.error.message);

	// Return the dto data if parsing is failed to continue the process.
	return dto as T;
};
