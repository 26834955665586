import { DateTime } from 'luxon';

/**
 * Format date to readable.
 * @param date - Date value.
 * @param format - Format type. Default is 'MMMM dd, yyyy'.
 * Check here for definition and other formats https://moment.github.io/luxon/#/formatting?id=table-of-tokens .
 */
export function formatDate(date: Date | null, format = 'MMMM dd, yyyy'): string {
	if (!date) {
		return '';
	}
	return DateTime.fromJSDate(date).toFormat(format);
}
