import { inject, Injectable } from '@angular/core';

import { UserFilterParamsDto, UserSortFieldDto } from '../dtos/user-filter-params.dto';
import { BaseFilterParams } from '../models/base-filter-params';
import { UserFilterParams, UserSortField } from '../models/user-filter-params';

import { MapperToDto } from './mappers';
import { SortMapper } from './sort.mapper';
import { PaginationParamsMapper } from './pagination.mapper';

const USER_SORT_FIELD_MAP_TO_DTO: Readonly<Record<UserSortField, UserSortFieldDto>> = {
	[UserSortField.Email]: UserSortFieldDto.Email,
	[UserSortField.Company]: UserSortFieldDto.Company,
	[UserSortField.Name]: UserSortFieldDto.Name,
	[UserSortField.Role]: UserSortFieldDto.Role,
};

/** User filter params mapper. */
@Injectable({
	providedIn: 'root',
})
export class UserFilterParamsMapper implements MapperToDto<UserFilterParamsDto, BaseFilterParams.Search> {
	private readonly sortMapper = inject(SortMapper);

	private readonly paginationParamsMapper = inject(PaginationParamsMapper);

	/** @inheritdoc */
	public toDto(params: UserFilterParams): UserFilterParamsDto {
		return {
			...this.paginationParamsMapper.toDto(params),
			name: params.search,
			sort: this.sortMapper.toDto(params.sort, USER_SORT_FIELD_MAP_TO_DTO),
		};
	}

	/**
	 * Maps search params to filter dto.
	 * @param params Params data.
	 */
	public toSearchDto(params: BaseFilterParams.Search): UserFilterParamsDto {
		return {
			name: params.search,
		};
	}
}
