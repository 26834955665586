import { Injectable, inject } from '@angular/core';

import { Customer } from '../models/customer';
import { QuoteDetail } from '../models/quote/quote-detail';
import { Note } from '../models/quote/note';

import { User } from '../models/user';

import { Location } from '../models/location';

import { AppConfig } from './app.config';

/**
 * Urls used within the application.
 * Stringified for convenience, since most of the Angular's HTTP tools work with strings.
 */
@Injectable({ providedIn: 'root' })
export class AppUrlsConfig {
	private readonly appConfigService = inject(AppConfig);

	/** Auth-related routes. */
	public readonly auth = {
		login: this.toApi('login'),
		logout: this.toApi('logout'),
	} as const;

	/** Routes for getting/editing current user's info. */
	public readonly user = {
		currentProfile: this.toApi('profile'),
		list: this.toApi('users'),
		getAll: this.toApi('users', 'get-all'),
		changePassword: this.toApi('change-password'),
		forgotPassword: this.toApi('forgot-password'),
		detail: (id: User['id']) => this.toApi('users', id.toString()),
		picture: (id: User['id']) => this.toApi('users', id.toString(), 'picture'),
		profilePicture: this.toApi('profile-picture'),
	} as const;

	/** Routes related to quotes. */
	public readonly quote = {
		list: this.toApi('quotes'),
		revisions: (id: QuoteDetail['id']) => this.toApi('revisions', id.toString(), 'revisions'),
		createNote: (id: QuoteDetail['id']) => this.toApi('quotes', id.toString(), 'notes'),
		editNote: (id: QuoteDetail['id'], noteId: Note['id']) =>
			this.toApi('quotes', id.toString(), 'notes', noteId.toString()),
		jobOptions: this.toApi('jobs/form-data'),
		stages: this.toApi('quotes', 'stages'),
		detail: (id: QuoteDetail['id']) => this.toApi('quotes', id.toString()),
		storeDescription: (id: QuoteDetail['id']) => this.toApi('quotes', id.toString(), 'description'),
		nonBillableEquipment: (id: QuoteDetail['id']) => this.toApi('quotes', id.toString(), 'non-billable-equipments'),
		equipment: (id: QuoteDetail['id']) => this.toApi('quotes', id.toString(), 'equipments'),
		editLabors: (id: QuoteDetail['id']) => this.toApi('quotes', id.toString(), 'labors'),
		editOwnedEquipment: (id: QuoteDetail['id']) => this.toApi('quotes', id.toString(), 'owned-equipments'),
		editStorageHandling: (id: QuoteDetail['id']) => this.toApi('quotes', id.toString(), 'storage-handling'),
		editTransportation: (id: QuoteDetail['id']) => this.toApi('quotes', id.toString(), 'transportations'),
		editRentalEquipment: (id: QuoteDetail['id']) => this.toApi('quotes', id.toString(), 'rental-equipments'),
		editOtherSection: (id: QuoteDetail['id']) => this.toApi('quotes', id.toString(), 'others'),
		summary: (id: QuoteDetail['id']) => this.toApi('quotes', id.toString(), 'summary'),
		uploadInternalFile: (id: QuoteDetail['id']) => this.toApi('quotes', id.toString(), 'internal-files'),
		downloadOrDeleteInternalFile: (id: QuoteDetail['id'], fileId: number) =>
			this.toApi('quotes', id.toString(), 'internal-files', fileId.toString()),
		availableStages: (id: QuoteDetail['id']) => this.toApi('quotes', 'stages', id.toString()),
		editStage: (id: QuoteDetail['id']) => this.toApi('quotes', id.toString(), 'stages'),
		mutateClarification: (id: QuoteDetail['id']) => this.toApi('quotes', id.toString(), 'clarifications'),
		reasons: this.toApi('quotes', 'stages', 'lost-reasons'),
		listStatistic: this.toApi('quotes', 'statistics'),
		paginatedList: this.toApi('quotes', 'pagination'),
		downloadPreview: (id: QuoteDetail['id']) => this.toApi('quotes', id.toString(), 'download-pdf'),
		editLaborAndEquipmentBundles: (id: QuoteDetail['id']) => this.toApi('quotes', id.toString(), 'labor-equipment-bundles'),
		statuses: this.toApi('quotes', 'statuses'),
	} as const;

	/** Routes related to revisions. */
	public readonly revisions = {
		detail: (id: QuoteDetail['id']) => this.toApi('revisions', id.toString()),
		stages: this.toApi('revisions', 'stages'),
		availableStages: (id: QuoteDetail['id']) => this.toApi('revisions', 'stages', id.toString()),
		createNote: (id: QuoteDetail['id']) => this.toApi('revisions', id.toString(), 'notes'),
		editStage: (id: QuoteDetail['id']) => this.toApi('revisions', id.toString(), 'stages'),
		editNote: (id: QuoteDetail['id'], noteId: Note['id']) =>
			this.toApi('revisions', id.toString(), 'notes', noteId.toString()),
		storeDescription: (id: QuoteDetail['id']) => this.toApi('revisions', id.toString(), 'description'),
		nonBillableEquipment: (id: QuoteDetail['id']) => this.toApi('revisions', id.toString(), 'non-billable-equipments'),
		equipment: (id: QuoteDetail['id']) => this.toApi('revisions', id.toString(), 'equipments'),
		editLabors: (id: QuoteDetail['id']) => this.toApi('revisions', id.toString(), 'labors'),
		editOwnedEquipment: (id: QuoteDetail['id']) => this.toApi('revisions', id.toString(), 'owned-equipments'),
		editStorageHandling: (id: QuoteDetail['id']) => this.toApi('revisions', id.toString(), 'storage-handling'),
		editTransportation: (id: QuoteDetail['id']) => this.toApi('revisions', id.toString(), 'transportations'),
		editRentalEquipment: (id: QuoteDetail['id']) => this.toApi('revisions', id.toString(), 'rental-equipments'),
		editOtherSection: (id: QuoteDetail['id']) => this.toApi('revisions', id.toString(), 'others'),
		uploadInternalFile: (id: QuoteDetail['id']) => this.toApi('revisions', id.toString(), 'internal-files'),
		downloadOrDeleteInternalFile: (id: QuoteDetail['id'], fileId: number) =>
			this.toApi('revisions', id.toString(), 'internal-files', fileId.toString()),
		mutateClarification: (id: QuoteDetail['id']) => this.toApi('revisions', id.toString(), 'clarifications'),
		restoreQuote: (id: QuoteDetail['id']) => this.toApi('revisions', id.toString(), 'restore'),
		restoreRevision: (quoteId: QuoteDetail['id'], revisionId: QuoteDetail['id']) =>
			this.toApi('revisions', quoteId.toString(), 'restore', revisionId.toString()),
		list: (id: QuoteDetail['id']) => this.toApi('revisions', id.toString(), 'revisions'),
		downloadPreview: (id: QuoteDetail['id']) => this.toApi('revisions', id.toString(), 'download-pdf'),
		summary: (id: QuoteDetail['id']) => this.toApi('revisions', id.toString(), 'summary'),
		editLaborAndEquipmentBundles: (id: QuoteDetail['id']) => this.toApi('revisions', id.toString(), 'labor-equipment-bundles'),
	} as const;

	/** Routes related to customers. */
	public readonly customer = {
		list: this.toApi('customers'),
		details: (id: Customer['id']) => this.toApi('customers', id.toString()),
		contacts: (id: Customer['id']) => this.toApi('customers', id.toString(), 'contacts'),
	} as const;

	/** Routes related to equipment. */
	public readonly equipment = {
		nonBillableList: this.toApi('non-billable-equipments'),
		list: this.toApi('equipments'),
	} as const;

	/** Routes related to location. */
	public readonly location = {
		jobSites: (id: number) => this.toApi('locations', id.toString(), 'jobsites'),
		list: this.toApi('locations'),
		sameLocations: (id: Location['id']) => this.toApi('locations', id.toString(), 'locations-same-region'),
	} as const;

	/** Routes related to rate card. */
	public readonly rateCard = {
		list: this.toApi('rate-cards'),
		rateCards: this.toApi('rate-cards'),
	} as const;

	/** Routes related to clarification. */
	public readonly clarification = {
		list: this.toApi('clarifications'),
		divisions: this.toApi('clarifications', 'divisions'),
		endMarkets: this.toApi('clarifications', 'end-markets'),
		groups: this.toApi('clarifications', 'groups'),
		regionals: this.toApi('clarifications', 'regionals'),
	} as const;

	/** Routes related to permission. */
	public readonly permission = {
		list: this.toApi('permissions'),
		roles: this.toApi('permissions', 'roles'),
		userPermissions: (userId: number) => this.toApi('permissions', 'users', userId.toString(), 'permissions'),
		canCreateQuotes: (userId: number) => this.toApi('permissions', 'users', userId.toString(), 'can-create-quotes'),
		canViewQuote: (userId: number, quoteId: number) =>
			this.toApi('permissions', 'users', userId.toString(), 'can-view-quote', quoteId.toString()),
		canEditQuote: (userId: number, quoteId: number) =>
			this.toApi('permissions', 'users', userId.toString(), 'can-edit-quote', quoteId.toString()),
		canEditUsers: (userId: number) => this.toApi('permissions', 'users', userId.toString(), 'can-add-users'),
		regions: (userId: number) => this.toApi('permissions', 'customers', userId.toString(), 'regions'),
		canEditPermissions: (userId: number) =>
			this.toApi('permissions', 'users', userId.toString(), 'can-edit-permissions'),
		edit: (userId: number) => this.toApi('users', userId.toString(), 'permissions'),
		canApproveQuote: (userId: number) => this.toApi('permissions', 'users', userId.toString(), 'can-approve-quote'),
	} as const;

	/** Routes related to term and conditions. */
	public readonly terms = {
		list: this.toApi('term-and-conditions'),
	} as const;

	/** Routes related to logos. */
	public readonly logos = {
		list: this.toApi('logos'),
	} as const;

	/** Routes related to change orders. */
	public readonly changeOrders = {
		detail: (id: QuoteDetail['id'] | QuoteDetail['quoteNumber']) => this.toApi('change-orders', id.toString()),
		list: (quoteNumber: QuoteDetail['id'] | QuoteDetail['quoteNumber']) =>
			this.toApi('change-orders', quoteNumber.toString(), 'change-orders'),
		summary: (id: QuoteDetail['id']) => this.toApi('change-orders', id.toString(), 'summary'),
		stages: this.toApi('change-orders', 'stages'),
		availableStages: (id: QuoteDetail['id']) => this.toApi('change-orders', 'stages', id.toString()),
		editStage: (id: QuoteDetail['id']) => this.toApi('change-orders', id.toString(), 'stages'),
		equipment: (id: QuoteDetail['id']) => this.toApi('change-orders', id.toString(), 'equipments'),
		editLabors: (id: QuoteDetail['id']) => this.toApi('change-orders', id.toString(), 'labors'),
		editOwnedEquipment: (id: QuoteDetail['id']) => this.toApi('change-orders', id.toString(), 'owned-equipments'),
		editStorageHandling: (id: QuoteDetail['id']) => this.toApi('change-orders', id.toString(), 'storage-handling'),
		editTransportation: (id: QuoteDetail['id']) => this.toApi('change-orders', id.toString(), 'transportations'),
		editRentalEquipment: (id: QuoteDetail['id']) => this.toApi('change-orders', id.toString(), 'rental-equipments'),
		editOtherSection: (id: QuoteDetail['id']) => this.toApi('change-orders', id.toString(), 'others'),
		downloadPreview: (id: QuoteDetail['id']) => this.toApi('change-orders', id.toString(), 'download-pdf'),
		editLaborAndEquipmentBundles: (id: QuoteDetail['id']) => this.toApi('change-orders', id.toString(), 'labor-equipment-bundles'),
	} as const;

	/** Routes related to masquerade. */
	public readonly masquerade = {
		enter: (id: number) => this.toApi('masquerade', id.toString()),
		leave: this.toApi('masquerade'),
	} as const;

	/** Routes related to notifications. */
	public readonly notifications = {
		list: this.toApi('notifications'),
		markAsRead: this.toApi('notifications', 'mark-as-read'),
		markAllAsRead: this.toApi('notifications', 'mark-all-as-read'),
	} as const;

	/**
	 * Checks whether the url is application-scoped.
	 * @param url Url to check.
	 */
	public isApplicationUrl(url: string): boolean {
		return url.startsWith(this.appConfigService.apiUrl);
	}

	/**
	 * Checks whether the specified url is calling an auth-related endpoint.
	 * @param url Url to check.
	 */
	public isAuthUrl(url: string): boolean {
		return Object.values(this.auth).find(authUrl => authUrl.includes(url)) != null;
	}

	private toApi(...args: readonly string[]): string {
		const path = args.join('/');
		return new URL(path, this.appConfigService.apiUrl).toString();
	}
}
