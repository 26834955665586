type FormDataValue = string | number | File | null;

/**
 * Construct form data with DTO values.
 * @param data Dto data.
 */
export function constructFormData(data: Record<string, FormDataValue>): FormData {
	const formData = new FormData();

	Object.entries(data).forEach(([key, value]) => {
		if (value == null) {
			return;
		}
		if (value instanceof File) {
			formData.append(key, value, value.name);
			return;
		}
		formData.append(key, value.toString());
	});

	return formData;
}
