import { z } from 'zod';

/** Company dto schema. */
export const companyDtoSchema = z.object({
	id: z.number(),
	name: z.string(),
});

/** Company DTO. */
export type CompanyDto = Readonly<z.infer<typeof companyDtoSchema>>;
