<label class="label">
	@if (labelText !== null) {
		<span
			class="label__content"
			data-testid="label"
		>
			{{ labelText }}
		</span>
		@if (isRequired) {
			<span class="label__required-indicator">&#42;</span>
		}
	}
	<meic-form-error-wrapper>
		<ng-content />
	</meic-form-error-wrapper>
</label>
