import { Injectable } from '@angular/core';

import { CompanyDto } from '../dtos/company.dto';
import { Company } from '../models/company';

import { MapperFromDto } from './mappers';

/** Company mapper. */
@Injectable({
	providedIn: 'root',
})
export class CompanyMapper implements MapperFromDto<CompanyDto, Company> {
	/** @inheritdoc */
	public fromDto(dto: CompanyDto): Company {
		return {
			id: dto.id,
			name: dto.name,
		};
	}
}
