<meic-card class="snackbar__card">
	<div
		title
		class="card__title"
	>
		<span
			class="icon"
			[class.error]="snackbarData.type === 'error'"
			[class.warning]="snackbarData.type === 'warning'"
			[class.info]="snackbarData.type === 'info'"
			[class.success]="snackbarData.type === 'success'"
		>
			@switch (snackbarData.type) {
				@case ("error") {
					<mat-icon fontSet="material-icons-outlined">error_outlined</mat-icon>
				}
				@case ("info") {
					<mat-icon fontSet="material-icons-outlined">info</mat-icon>
				}
				@case ("warning") {
					<mat-icon fontSet="material-icons-outlined">warning</mat-icon>
				}
				@case ("success") {
					<mat-icon fontSet="material-icons-outlined">check_circle</mat-icon>
				}
				@default {
					<mat-icon fontSet="material-icons-outlined">error</mat-icon>
				}
			}
		</span>
		<span>{{ snackbarData.title }}</span>
	</div>
	<div
		content
		class="wrapper"
	>
		<div [innerHTML]="snackbarData.content"></div>
	</div>
	<div
		headerActions
		class="cart__actions"
	>
		@if (snackbarData.notifyDate) {
			<span class="note">{{ toReadableNotifyDate(snackbarData.notifyDate) }}</span>
		}
		<button
			(click)="onDismissAlert()"
			class="close-button"
			type="button"
			mat-icon-button
		>
			<mat-icon>close</mat-icon>
		</button>
	</div>
</meic-card>
