import { z } from 'zod';

/** User role type dto. */
export enum UserRoleTypeDto {
	Default = 'default',
	Region = 'region',
	Location = 'location',
	Company = 'company',
}

/** User role dto schema. */
export const userRoleDtoSchema = z.object({
	id: z.number(),
	name: z.string(),
	type: z.nativeEnum(UserRoleTypeDto),
	description: z.string(),
});

/** User role dto. */
export type UserRoleDto = Readonly<z.infer<typeof userRoleDtoSchema>>;
