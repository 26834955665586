import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { User, UserEdit } from '../models/user';
import { UserMapper } from '../mappers/user.mapper';
import { UserDto, userDtoSchema } from '../dtos/user.dto';
import { secureParse } from '../utils/secureParse';
import { UserFilterParamsMapper } from '../mappers/user-filter-params.mapper';
import { composeHttpParams } from '../utils/compose-http-params';
import { DataWithWrapperDto } from '../dtos/data-with-wrapper.dto';
import { successResponseDtoSchema } from '../dtos/success-response.dto';
import { PasswordChangeMapper } from '../mappers/password-change.mapper';
import { ForgotPassword, PasswordChange } from '../models/password-change';
import { AppErrorMapper } from '../mappers/app-error.mapper';
import { forgotPasswordDtoSchema } from '../dtos/password-change.dto';

import { constructFormData } from '../utils/construct-form-data';

import { UserFilterParams } from '../models/user-filter-params';

import { Pagination } from '../models/pagination';

import { ListResponseMapper } from '../mappers/list-response.mapper';
import { ListResponseDto } from '../dtos/list-response.dto';

import { BaseFilterParams } from '../models/base-filter-params';

import { AppUrlsConfig } from './app-urls.config';

/** Performs CRUD operations for users. */
@Injectable({
	providedIn: 'root',
})
export class UserApiService {

	private readonly apiUrls = inject(AppUrlsConfig);

	private readonly httpClient = inject(HttpClient);

	private readonly userMapper = inject(UserMapper);

	private readonly passwordChangeMapper = inject(PasswordChangeMapper);

	private readonly appErrorMapper = inject(AppErrorMapper);

	private readonly listMapper = inject(ListResponseMapper);

	private readonly userFilterParamsMapper = inject(UserFilterParamsMapper);

	/** Returns current user info.*/
	public getCurrentUser(): Observable<User> {
		return this.httpClient.get(this.apiUrls.user.currentProfile)
			.pipe(
				map(response => secureParse(response, userDtoSchema)),
				map(userDto => this.userMapper.fromDto(userDto, this.currentProfilePictureUrl)),
			);
	}

	/**
	 * Get available users.
	 * @param filters Filter params.
	 */
	public getList(filters: BaseFilterParams.Search): Observable<User[]> {
		const filtersDto = this.userFilterParamsMapper.toSearchDto(filters);
		const params = composeHttpParams(filtersDto);
		return this.httpClient.get<DataWithWrapperDto>(this.apiUrls.user.list, { params }).pipe(
			map(response => secureParse(response.data, userDtoSchema.array())),
			map(dtos => dtos.map(dto => this.userMapper.fromDto(dto, this.getUserProfilePictureUrl(dto.id)))),
		);
	}

	/**
	 * Changes password of current user.
	 * @param data Data required for password changing.
	 */
	public changePassword(data: PasswordChange): Observable<void> {
		return this.httpClient.post<unknown>(
			this.apiUrls.user.changePassword,
			this.passwordChangeMapper.toDto(data),
		)
			.pipe(
				map(response => secureParse(response, successResponseDtoSchema)),
				map(() => undefined),
				this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
					this.passwordChangeMapper,
				),
			);
	}

	/**
	 * Handle action when user forgot password.
	 * This action return token to use for change password and will sent user an email with the forgot password url with token.
	 * @param email User email.
	 */
	public handleForgotPassword(email: User['email']): Observable<ForgotPassword> {
		return this.httpClient.post<unknown>(
			this.apiUrls.user.forgotPassword,
			{ email },
		)
			.pipe(
				map(response => secureParse(response, forgotPasswordDtoSchema)),
				map(dto => this.passwordChangeMapper.fromForgotPasswordDto(dto)),
				this.appErrorMapper.catchHttpErrorToAppError(),
			);
	}

	/**
	 * Edit user profile.
	 * @param data Edit data.
	 * @param id User id.
	 */
	public edit(data: UserEdit, id: User['id']): Observable<void> {
		const body = this.userMapper.toEditDto(data);
		return this.httpClient.post(this.apiUrls.user.detail(id), constructFormData(body)).pipe(
			map(() => undefined),
		);
	}

	/**
	 * Get user detail.
	 * @param id User ID.
	 */
	public getDetail(id: User['id']): Observable<User> {
		return this.httpClient.get(this.apiUrls.user.detail(id)).pipe(
			map(response => secureParse(response, userDtoSchema)),
			map(dto => this.userMapper.fromDto(dto, this.getUserProfilePictureUrl(dto.id))),
		);
	}

	/**
	 * Get the user profile picture URL.
	 * @param id User ID.
	 */
	private getUserProfilePictureUrl(id: User['id']): string {
		return `${this.apiUrls.user.picture(id)}`;
	}

	private get currentProfilePictureUrl(): string {
		return this.apiUrls.user.profilePicture;
	}

	/**
	 * Get user list with pagination params.
	 * @param filters User filters.
	 */
	public getListWithPagination(filters: UserFilterParams): Observable<Pagination<User>> {
		const filtersDto = this.userFilterParamsMapper.toDto(filters);
		const params = composeHttpParams(filtersDto);
		return this.httpClient.get<ListResponseDto<UserDto>>(
			this.apiUrls.user.getAll,
			{ params },
		).pipe(
			map(page => this.listMapper.fromDto(page, this.userMapper.fromDto.bind(this.userMapper))),
		);
	}

	/**
	 * Edit profile picture.
	 * @param file Profile picture file.
	 */
	public editProfilePicture(file: File): Observable<void> {
		return this.httpClient.post(this.apiUrls.user.profilePicture, constructFormData({ file })).pipe(
			map((() => undefined)),
		);
	}
}
