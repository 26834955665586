import { z } from 'zod';

/** User role type. */
export enum UserRoleType {
	Default = 'default',
	Region = 'region',
	Company = 'company',
	Location = 'location',
}

/** User role schema. */
export const userRoleSchema = z.object({
	id: z.number(),
	name: z.string(),
	description: z.string(),
	type: z.nativeEnum(UserRoleType),
});

/** User role. */
export type UserRole = Readonly<z.infer<typeof userRoleSchema>>;
