import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, merge, Observable, of, shareReplay, Subject, switchMap, tap } from 'rxjs';

import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';

import { Login } from '../models/login';
import { User } from '../models/user';

import { ForgotPassword, PasswordChange } from '../models/password-change';

import { AuthApiService } from './auth-api.service';
import { UserApiService } from './user-api.service';

/**
 * Stateful service for storing/managing information about the current user.
 */
@Injectable({
	providedIn: 'root',
})
export class UserService {
	private readonly userApiService = inject(UserApiService);

	private readonly authService = inject(AuthApiService);

	/** Current user. `null` when a user is not logged in. */
	public readonly currentUser$: Observable<User | null>;

	/** Whether the current user is authorized. */
	public readonly isAuthorized$: Observable<boolean>;

	private refresh$ = new BehaviorSubject<void>(undefined);

	private logout$ = new Subject<null>();

	public constructor() {
		this.currentUser$ = this.initCurrentUserStream();
		this.isAuthorized$ = this.currentUser$.pipe(map(user => user != null));
	}

	/**
	 * Login a user with email and password.
	 * @param loginData Login data.
	 */
	public login(loginData: Login): Observable<void> {
		return this.authService.login(loginData);
	}

	/**
	 * Change user password.
	 * @param changePasswordData Change password data.
	 */
	public changePassword(changePasswordData: PasswordChange): Observable<void> {
		return this.userApiService.changePassword(changePasswordData);
	}

	/**
	 * Forgot password.
	 * @param email User email.
	 */
	public forgotPassword(email: User['email']): Observable<ForgotPassword> {
		return this.userApiService.handleForgotPassword(email);
	}

	/** Logout the user. */
	public logout(): Observable<void> {
		return this.authService.logout().pipe(tap(() => this.logout$.next(null)));
	}

	private initCurrentUserStream(): Observable<User | null> {
		return this.refresh$.pipe(
			switchMap(() => merge(
				this.userApiService.getCurrentUser(),
				this.logout$,
			)),
			catchError((error: unknown) => {
				if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.Unauthorized) {
					return of(null);
				}

				throw error;
			}),
			shareReplay({ bufferSize: 1, refCount: true }),
		);
	}

	/** Refresh user information. */
	public refresh(): void {
		this.refresh$.next();
	}
}
