import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatCardModule } from '@angular/material/card';

/** Card. */
@Component({
	selector: 'meic-card',
	templateUrl: './card.component.html',
	styleUrl: './card.component.css',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [MatCardModule],
})
export class CardComponent {

	/** Title of the card. */
	@Input()
	public title = '';

	/** Want to use divider. */
	@Input()
	public hasDivider = true;

	/** Should add spacing between header and content. */
	@Input()
	public hasSpacing = true;

	/** Emit if the title is clicked. */
	@Output()
	public readonly titleClicked = new EventEmitter<void>();
}
