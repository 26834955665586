import { ChangeDetectionStrategy, Component, ContentChild, Input, ViewChild } from '@angular/core';
import { NgControl, NgModel, FormControlDirective, Validators } from '@angular/forms';

import { FormErrorWrapperComponent } from '../form-error-wrapper/form-error-wrapper.component';

/**
 * Label component. Displays error and label for the input component.
 */
@Component({
	selector: 'meic-label',
	templateUrl: './label.component.html',
	styleUrls: ['./label.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [FormErrorWrapperComponent],
})
export class LabelComponent {
	/**
	 * Text of control's label.
	 */
	@Input()
	public labelText: string | null = null;

	private required = false;

	/** Wrapper displaying errors. */
	@ViewChild(FormErrorWrapperComponent, { static: true })
	public errorWrapper!: FormErrorWrapperComponent;

	/** Catch inner input by form control directive. */
	@ContentChild(NgControl)
	public set input(i: NgModel | FormControlDirective) {
		this.passErrorInput(i);
	}

	private passErrorInput(input: NgModel | FormControlDirective | undefined): void {
		if (input == null) {
			return;
		}

		// Need to pass this manually, since ContentChild does not catch the slot that is nested.
		this.errorWrapper.input = input;

		if (input?.control != null) {
			this.required = input.control.hasValidator(Validators.required);
		}
	}

	/** Is field required or not. */
	public get isRequired(): boolean {
		return this.required;
	}
}
