import { BaseFilterParams } from './base-filter-params';
import { SortOptions } from './sort-options';

/** User sort field. */
export enum UserSortField {
	Name = 'name',
	Email = 'email',
	Role = 'role',
	Company = 'company',
}

/** User filter params. */
export type UserFilterParams = BaseFilterParams.Combined & {

	/** Sort option. */
	sort?: SortOptions<UserSortField>;
};
